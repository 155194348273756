import _isNumber from 'lodash/isNumber'
import { SET_SERVER_TIME_DATA } from 'services/server-time/actions'
import { BOOTSTRAP_PHASE_INIT } from 'services/app'
import { SET_APP_BOOTSTRAP_PHASE } from 'services/app/actions'
import { getLogger } from 'log'
import {
  getAuthIsLoggedIn,
  getAuthOptimisicRenewalSeconds,
  authToShopify,
} from '.'
import {
  SET_AUTH_DATA,
  AUTH_RENEW_CHECK,
  doAuthRenew,
  authRenewCheck,
  GET_SHOPIFY_MULTIPASS_TOKEN,
  setShopifyMultipassTokenError,
} from './actions'

/**
 * The auth renewal check in seconds
 */
const AUTH_RENEW_CHECK_INTERVAL_SECONDS = 300

/**
 * The timeout to run the auth check interval
 */
const AUTH_RENEW_CHECK_INTERVAL_TIMEOUT = AUTH_RENEW_CHECK_INTERVAL_SECONDS * 1000

/**
 * A interval id for the auth renewal check
 * @type {Number}
 */
let _authRenewalCheckInterval

/**
 * A timestamp based lock for auth renewals to prevent thrashing
 * @type {Number}
 */
let _authRenewalLockTimestamp

/**
 * A timestamp for an optimistis token renewal
 * @type {Number}
 */
let _authRenewalOptimisticTimestamp

/**
 * Set the auth optimistic renewal timestamp, defaults to
 * now plus value from getAuthOptimisicRenewalSeconds()
 * @param {Number} [timestamp] The timestamp to set renewal from (default to now)
 * @param {Number} [renewalSeconds] The next renewal in seconds
 */
function setAuthRenewalOptimisticTimestamp (
  timestamp = Date.now(),
  renewalSeconds = getAuthOptimisicRenewalSeconds(),
) {
  if (!_isNumber(timestamp) || timestamp < 0) {
    throw new Error('The timestamp argument is expected to be a number greater than or equal to zero')
  }
  if (!_isNumber(renewalSeconds) || renewalSeconds < 0) {
    throw new Error('The renewalSeconds argument is expected to be a number greater than or equal to zero')
  }
  _authRenewalOptimisticTimestamp = Math.floor(timestamp / 1000) + renewalSeconds
  return _authRenewalOptimisticTimestamp
}

/**
 * Handle the auth renewal process
 * @param {Object} options The options
 * @param {Map} options.auth The auth state
 * @param {Map} options.serverTime The serverTime state
 * @param {Function} options.dispatch A redux dispatch function
 * @param {Number} [options.authRenewalLockTimestamp] A timestamp lock for the renewal
 */
async function handleAuthRenew (options = {}) {
  const {
    auth,
    dispatch,
    authRenewalLockTimestamp = _authRenewalLockTimestamp,
    authRenewalOptimisticTimestamp = _authRenewalOptimisticTimestamp,
  } = options
  const log = getLogger() || console
  // If the user is not logged in there is nothing to refresh
  if (!getAuthIsLoggedIn(auth)) {
    log.info({ message: 'Not renewing not logged in', watcher: 'auth', idExpires })
    return
  }
  const idExpires = auth.get('idExpires')
  const currentTimestamp = Math.floor(Date.now() / 1000)
  // Only renew if the idExpires exists and is within check interval seconds
  // of the current timestamp
  if (!idExpires) {
    log.info({ message: 'Not renewing auth idExpires does not exist', watcher: 'auth', idExpires })
    return
  }
  const timestampIdExpired = currentTimestamp - AUTH_RENEW_CHECK_INTERVAL_SECONDS
  const idExpired = idExpires <= timestampIdExpired
  const optimisticRenewal = authRenewalOptimisticTimestamp <= currentTimestamp
  if (idExpired || optimisticRenewal) {
    if (idExpired) {
      log.info({
        message: 'Renewing auth due to idExpires is less than or equal to timestamp id expired',
        watcher: 'auth',
        idExpires,
        currentTimestamp,
        timestampIdExpired,
      })
    }
    if (optimisticRenewal) {
      log.info({
        message: 'Renewing auth due to optimistic renewal timestamp is less than or equal to optimitic renewal timestamp',
        watcher: 'auth',
        idExpires,
        currentTimestamp,
        authRenewalOptimisticTimestamp,
      })
    }
    if (authRenewalLockTimestamp && authRenewalLockTimestamp > currentTimestamp) {
      log.info({
        message: 'Not renewing auth renewal lock timestamp greater than current timestamp',
        watcher: 'auth',
        idExpires,
        currentTimestamp,
        authRenewalLockTimestamp,
      })
      return
    }
    // If auth is already processing this should be triggered again by another
    // auth action when it is done
    const processingRenew = auth.get('processingRenew')
    if (processingRenew) {
      log.info({
        message: 'Not renewing auth renew processing',
        watcher: 'auth',
        idExpires,
        currentTimestamp,
        authRenewalLockTimestamp,
        processingRenew,
      })
      return
    }
    // Set the next optimistic renewal
    setAuthRenewalOptimisticTimestamp()
    // If we got this far we need to renew the token
    try {
      await dispatch(doAuthRenew(auth))
    } catch (e) {
      // In the case of an error we most likely weren't able to renew
      // schedule next renewal attempt in the near future
      log.error(e)
      setAuthRenewalOptimisticTimestamp(Date.now(), 300)
    }
    // Lock renewal attempts so they can't occur more then once a minute
    // to prevent thrashing
    _authRenewalLockTimestamp = Math.floor(Date.now() / 1000) + 60
    return
  }
  log.info({
    message: 'Not renewing auth optimistic renewal timestamp and idExpires has not passed',
    watcher: 'auth',
    idExpires,
    currentTimestamp,
    authRenewalOptimisticTimestamp,
  })
}

/**
 * Clear the auth renewal check interval if it exists
 */
function clearAuthRenewalCheckInterval () {
  if (_authRenewalCheckInterval) {
    clearInterval(_authRenewalCheckInterval)
    _authRenewalCheckInterval = undefined
  }
}

/**
 * Initialize the auth renwal iterval first clearing any existing interval
 * @param {Object} options The the options
 * @param {Function} options.dispatch A redux dispatch function
 */
function initAuthRenewalCheckInterval (options = {}) {
  const { dispatch } = options
  clearAuthRenewalCheckInterval()
  // There is no interval set yet so dispatch the check
  dispatch(authRenewCheck())
  _authRenewalCheckInterval = setInterval(() => {
    dispatch(authRenewCheck())
  }, AUTH_RENEW_CHECK_INTERVAL_TIMEOUT)
}

export function authBootStrapWatcher ({ after }) {
  return after(SET_APP_BOOTSTRAP_PHASE, ({ state, dispatch }) => {
    const { app } = state
    switch (app.get('bootstrapPhase')) {
      case BOOTSTRAP_PHASE_INIT: {
        setAuthRenewalOptimisticTimestamp()
        initAuthRenewalCheckInterval({ dispatch })
        // On window blur the timeout does run so re-init the renew process
        window.addEventListener('focus', () => initAuthRenewalCheckInterval({ dispatch }))
        break
      }
      default:
        break
    }
  })
}

export function authRenewalWatcher ({ after }) {
  return after([
    SET_SERVER_TIME_DATA,
    SET_AUTH_DATA,
    AUTH_RENEW_CHECK,
  ], ({ state, dispatch }) => {
    const { auth, serverTime } = state
    handleAuthRenew({ auth, serverTime, dispatch })
  })
}

export function watchGetShopifyMultipassToken ({ after }) {
  return after([
    GET_SHOPIFY_MULTIPASS_TOKEN,
  ], async ({ action, state, dispatch }) => {
    const { auth } = state
    const { payload } = action
    const { redirect } = payload
    try {
      const url = await authToShopify({ auth, redirect })
      if (global && global.location) {
        global.location.href = url
      }
    } catch (e) {
      dispatch(setShopifyMultipassTokenError(`Error authenticating to shopify: ${e}`))
    }
  })
}
