import get from 'lodash/get'
import { Map } from 'immutable'
import { BOOTSTRAP_PHASE_INIT } from 'services/app'
import { SET_APP_BOOTSTRAP_PHASE } from 'services/app/actions'
import { RESET_FEATURE_TRACKING, SET_FEATURE_TRACKING_COUNT, SET_FEATURE_TRACKING_DATA } from 'services/feature-tracking/actions'
import { createToolTipDataFromFeatureTrackingData } from '.'
import { initToolTipFeatureTracking } from './actions'

export function watchBootstrapInit ({ after }) {
  return after(SET_APP_BOOTSTRAP_PHASE, ({ state, dispatch }) => {
    const { featureTracking } = state
    const data = featureTracking.get('data', Map())
    const tooltipData = createToolTipDataFromFeatureTrackingData(data)
    dispatch(initToolTipFeatureTracking(tooltipData))
  }).when((o) => get(o, 'action.payload.phase') === BOOTSTRAP_PHASE_INIT)
}

export function watchFeatureTrackingChange ({ after }) {
  return after([
    SET_FEATURE_TRACKING_COUNT,
    SET_FEATURE_TRACKING_DATA,
    RESET_FEATURE_TRACKING,
  ], ({ state, prevState, dispatch }) => {
    const { featureTracking: featureTrackingPrev } = prevState
    const { featureTracking: featureTrackingNext } = state
    const prev = featureTrackingPrev.get('data', Map())
    const next = featureTrackingNext.get('data', Map())
    const changed = !prev.equals(next)
    if (changed) {
      const tooltipData = createToolTipDataFromFeatureTrackingData(next)
      dispatch(initToolTipFeatureTracking(tooltipData))
    }
  })
}
