/* eslint-disable import/prefer-default-export */
import _get from 'lodash/get'
import { Map, List, fromJS } from 'immutable'
import { get as apiGet, TYPE_BROOKLYN } from 'api-client'
import { getMultiplePmSections } from 'services/pm-section/actions'
import {
  getPmScreenProcessing,
  getPmScreenErrorCreated,
  getPmScreenDataCreated,
} from '.'
import {
  setPmScreen as setPmScreenAction,
  setPmScreenError as setPmScreenErrorAction,
  setPmScreenProcessing as setPmScreenProcessingAction,
} from './actions'

/**
 * The time to wait on error before getting a screen again
 */
const GET_PM_SCREEN_ERROR_CACHE_TIME = (300 * 1000)

/**
 * The time to wait on data before getting a screen again
 */
const GET_PM_SCREEN_DATE_CACHE_TIME = (60 * 1000)

/**
 * Get a page manager screen and dispatch actions
 * @param {Object} options The options
 * @param {import('redux').Dispatch} options.dispatch A redux dispatch function
 * @param {Map} options.auth The auth state
 * @param {Map} options.pmScreen The page manager screen state
 * @param {String} options.screenType The page manager screen type
 * @param {String} options.language The requested language
 * @param {Number} options.sectionsLimit A limit for the number of sections
 * @param {Date} options.now The current date
 */
export async function getPmScreen (options = {}) {
  const {
    auth = Map(),
    pmScreen = Map(),
    dispatch,
    screenType,
    language,
    sectionsLimit,
    now = new Date(),
  } = options
  try {
    const processing = getPmScreenProcessing({ pmScreen, screenType, language })
    const errorCreated = getPmScreenErrorCreated({ pmScreen, screenType, language })
    const dataCreated = getPmScreenDataCreated({ pmScreen, screenType, language })
    // Don't get page manager screen if already processing
    if (processing) {
      return
    }
    // Don't get page manager screen if there was an error recently
    if (errorCreated && now.getTime() < (errorCreated.getTime() + GET_PM_SCREEN_ERROR_CACHE_TIME)) {
      return
    }
    // Don't get page manager data if data was fetch recently
    if (dataCreated && now.getTime() < (dataCreated.getTime() + GET_PM_SCREEN_DATE_CACHE_TIME)) {
      return
    }
    dispatch(setPmScreenProcessingAction(screenType, language, true))
    const response = await apiGet(`page-manager/screen/${screenType}`, { language, peopleRowFeature: 'true' }, { auth }, TYPE_BROOKLYN)
    const data = fromJS(_get(response, 'body', {}))
    dispatch(setPmScreenAction(screenType, language, data))
    const sectionIds = data.get('sectionIds') || List()
    dispatch(getMultiplePmSections({ sectionIds, screenType, limit: sectionsLimit }))
  } catch (e) {
    dispatch(setPmScreenErrorAction(screenType, language, e))
  }
}
