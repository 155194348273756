import {
  URL_ACCOUNT_UPDATE_PAYMENT,
} from 'services/url/constants'
import { SET_PAYMENT_DATA } from 'services/payment/actions'
import { doAuthRenew } from 'services/auth/actions'
import {
  updateAdyenAuthorization,
  ADYEN_PAYMENT_RESPONSE_CODE_AUTHORIZED,
} from 'services/adyen'
import * as actions from './actions'

function updatePaymentPage ({ state }) {
  const { resolver } = state
  const path = resolver.getIn(['data', 'path'])

  return (
    path === URL_ACCOUNT_UPDATE_PAYMENT
  )
}

export function watchUpdatePayment ({ after }) {
  return after([
    SET_PAYMENT_DATA,
  ], async ({ state, dispatch }) => {
    const { payment, auth, velocityCheck } = state
    const paymentSuccess = payment.getIn(['data', 'payments', 'success'])
    const paymentResultCode = payment.getIn(['data', 'payments', 'data', 'resultCode'])
    const paymentIsAuthorized = paymentResultCode === ADYEN_PAYMENT_RESPONSE_CODE_AUTHORIZED
    const velocityCheckToken = velocityCheck.getIn(['data', 'velocityCheck', 'token'])

    // if the Adyen payment wasn't successful then bail
    if (!paymentSuccess) {
      return
    }

    // only call update payment if the card is immediately authorized
    if (paymentIsAuthorized) {
      const updatePaymentCreditCardOptions = {
        auth,
        velocityCheckToken,
        country: payment.getIn(['data', 'payments', 'billingAddress', 'country']) || payment.getIn(['data', 'billingInfo', 'billingAddress', 'country']),
        postalCode: payment.getIn(['data', 'payments', 'billingAddress', 'postalCode']),
        adyen: {
          shopperReference: payment.getIn(['data', 'payments', 'data', 'additionalData', 'recurring.shopperReference']),
          recurringDetailReference: payment.getIn(['data', 'payments', 'data', 'additionalData', 'recurring.recurringDetailReference']),
          cardSummary: payment.getIn(['data', 'payments', 'data', 'additionalData', 'cardSummary']),
          fundingSource: payment.getIn(['data', 'payments', 'data', 'additionalData', 'fundingSource']),
        },
      }

      dispatch(actions.processPaymentMethodUpdate(updatePaymentCreditCardOptions))
    } else {
      // for pending or received
      dispatch(actions.setUpdatePaymentOrderComplete(true))
    }
  })
    .when(updatePaymentPage)
}

export function watchUpdatePaymentOrderData ({ after }) {
  return after([
    actions.SET_UPDATE_PAYMENT_ORDER_DATA,
  ], async ({ state, dispatch }) => {
    const { updatePayment, auth, payment } = state
    const updatePaymentSuccess = updatePayment.getIn(['orderData', 'success'])

    if (updatePaymentSuccess) {
      const authorizationUuid = payment.getIn(['data', 'payments', 'authorization', 'uuid'])

      // send authorization data when checkout is complete
      if (authorizationUuid) {
        updateAdyenAuthorization({
          auth,
          uuid: authorizationUuid,
          billingAccountCreated: true,
        })
      }

      // do an auth renew to get new auth data
      await dispatch(doAuthRenew(auth))
    }
  })
    .when(updatePaymentPage)
}
