import { get as getFeatureTacking, set as setFeatureTracking } from './index'

export const SET_FEATURE_TRACKING_DATA = 'SET_FEATURE_TRACKING_DATA'
export const SET_FEATURE_TRACKING_PROCESSING = 'SET_FEATURE_TRACKING_PROCESSING'
export const RESET_FEATURE_TRACKING = 'RESET_FEATURE_TRACKING'
export const INCREMENT_FEATURE_IMPRESSION_COUNT = 'INCREMENT_FEATURE_IMPRESSION_COUNT'
export const RESET_FEATURE_IMPRESSION_COUNT = 'RESET_FEATURE_IMPRESSION_COUNT'
export const SET_FEATURE_TRACKING_COUNT = 'SET_FEATURE_TRACKING_COUNT'
export const ADD_HIDDEN_PM_SECTION = 'ADD_HIDDEN_PM_SECTION'

export function setFeatureTrackingProcessing (value) {
  return {
    type: SET_FEATURE_TRACKING_PROCESSING,
    payload: value,
  }
}

export function setFeatureTrackingData (data, processing = false) {
  return {
    type: SET_FEATURE_TRACKING_DATA,
    payload: { data, processing },
  }
}

export function resetFeatureTracking () {
  return {
    type: RESET_FEATURE_TRACKING,
  }
}

export function incrementFeatureImpressionCount (featureName) {
  return {
    type: INCREMENT_FEATURE_IMPRESSION_COUNT,
    payload: { featureName },
  }
}

exporring} Returns the modified source.
 */
function insertWrapDetails(source, details) {
  var length = details.length;
  if (!length) {
    return source;
  }
  var lastIndex = length - 1;
  details[lastIndex] = (length > 1 ? '& ' : '') + details[lastIndex];
  details = details.join(length > 2 ? ', ' : ' ');
  return source.replace(reWrapComment, '{\n/* [wrapped with ' + details + '] */\n');
}

module.exports = insertWrapDetails;
