import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { getBoundActions } from 'actions'
// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect as connectRedux } from 'react-redux'
import {
  historyRedirect,
  HISTORY_METHOD_REPLACE,
} from 'services/navigation'
import {
  isGiftRedeemPage,
} from 'services/url'
import {
  URL_GET_STARTED,
  URL_CART_CONFIRMATION,
  URL_ACCOUNT_PROFILE,
  URL_LOGOUT,
  URL_REMOTE_ACCOUNT_CREATE,
} from 'services/url/constants'
import { isPrimaryAccountProfile } from 'services/user-account'

function handleRedirects (props) {
  const {
    setResolverRedirectPath,
    onboardingIncomplete,
    checkoutComplete,
    isLoggedIn,
    language,
    resolver,
    history,
    auth,
    user,
  } = props

  if (!resolver.get('data')) return

  const url = resolver.getIn(['data', 'url'])
  const redirectType = resolver.getIn(['data', 'redirectType'])
  const redirectPath = resolver.get('redirectPath')
  const uid = user.getIn(['data', 'uid'])

  if (redirectType && url) {
    window.location.href = url
  } else if (!resolver.get('processing') && redirectPath) {
    setResolverRedirectPath(null)
    historyRedirect({
      historyMethod: HISTORY_METHOD_REPLACE,
      url: redirectPath,
      language,
      history,
      auth,
    })
  } else if (
    isLoggedIn
    && uid // this is needed as the uid can sometimes be falsy during processing phases
    && onboardingIncomplete
    && (checkoutComplete || !isPrimaryAccountProfile(props))
    && !isGiftRedeemPage(window.location.pathname)
    && window.location.pathname !== URL_GET_STARTED
    && window.location.pathname !== URL_CART_CONFIRMATION
    && window.location.pathname !== URL_ACCOUNT_PROFILE
    && window.location.pathname !== URL_LOGOUT
    && window.location.pathname !== URL_REMOTE_ACCOUNT_CREATE
  ) {
    historyRedirect({
      url: URL_GET_STARTED,
      language,
      history,
      auth,
    })
  }
}

class Resolver extends Component {
  componentDidMount () {
    if (!process.env.BROWSER) return

    const { setAppIsInitialRender } = this.props

    // set is initial render on mount
    setAppIsInitialRender(true)
    handleRedirects(this.props)
  }

  componentDidUpdate (prevProps) {
    if (prevProps === this.props) return
    if (!process.env.BROWSER) return

    const { setAppIsInitialRender, app } = this.props
    const isInitialRender = app.get('isInitialRender')

    // set is initial render to false on first path change
    if (isInitialRender !== false && this.props.resolver.get('path') !== prevProps.resolver.get('path')) {
      setAppIsInitialRender(false)
    }

    handleRedirects(this.props)
  }

  // eslint-disable-next-line class-methods-use-this
  render () {
    return null
  }
}

Resolver.propTypes = {
  setResolverRedirectPath: PropTypes.func.isRequired,
  setAppIsInitialRender: PropTypes.func.isRequired,
  resolver: ImmutablePropTypes.map.isRequired,
  user: ImmutablePropTypes.map.isRequired,
  app: ImmutablePropTypes.map.isRequired,
  onboarding: ImmutablePropTypes.map.isRequired,
  history: PropTypes.object.isRequired,
}

export default compose(
  connectRedux(
    (state) => {
      return {
        resolver: state.resolver,
        user: state.user,
        onboarding: state.onboarding,
        isLoggedIn: state.auth.get('jwt'),
        language: state.user.getIn(['data', 'language']),
        checkoutComplete: state.user.getIn(['data', 'checkout_complete']),
        onboardingIncomplete: state.onboarding.get('completed') === false
          && state.onboarding.get('processing') !== true,
        app: state.app,
      }
    },
    (dispatch) => {
      const actions = getBoundActions(dispatch)
      return {
        setResolverRedirectPath: actions.resolver.setResolverRedirectPath,
        setAppIsInitialRender: actions.app.setAppIsInitialRender,
      }
    },
  ),
)(Resolver)
