import _get from 'lodash/get'
import _slice from 'lodash/slice'
import _isNumber from 'lodash/isNumber'
import _parseInt from 'lodash/parseInt'
import _startsWith from 'lodash/startsWith'
import { each as eachPromise } from 'bluebird'
import { Map, List, fromJS } from 'immutable'
import { get as apiGet, TYPE_BROOKLYN } from 'api-client'
import { setMultiplePmListsProcessing as setMultiplePmListsProcessingAction } from 'services/pm-list/actions'
import { getPmListMultiple } from 'services/pm-list/middleware'
import {
  getVideos,
  getSeries,
  getTerms,
  getPromotions,
} from 'services/node'
import {
  URL_TOPICS,
  URL_HOME,
  URL_YOGA_TEACHERS,
} from 'services/url/constants'
import { get as getConfig } from 'config'
import { RESOLVER_TYPE_SHARE } from 'services/resolver/types'
import {
  setPmSection as setPmSectionAction,
  setPmSectionError as setPmSectionErrorAction,
  setPmSectionDone as setPmSectionDoneAction,
  setPmSectionProcessing as setPmSectionProcessingAction,
} from './actions'
import {
  getPmSectionProcessing,
  getPmSectionErrorCreated,
  getPmSectionDataCreated,
  preventSectionDataLoad,
  TYPE_CONTENT_LIST,
  TYPE_CONTENT_REFERENCES_LIST,
  TYPE_PEOPLE_LIST,
  TYPE_PLACEMENT,
  TYPE_PORTALS,
  TYPE_PROMOTION,
  GET_PM_SECTION_ERROR_CACHE_TIME,
} from '.'

const config = getConfig()
const tilesV2GraphqlActive = !!_get(config, ['features', 'tilesV2Graphql'])
const isYogaTeachersV3Enabled = !!_get(config, ['features', 'yogaTeachersPageV2'])
const isSharePageV3Enabled = !!_get(config, ['features', 'sharePageV3'])

/**
 * Get multiple page manger sections and dispatch associated actions
 * @param {Object} options The options
 * @param {import('redux').Dispatch} options.dispatch Redux dispatch function
 * @param {String} options.language The request language
 * @param {String} options.screenType The page manager screen type
 * @param {Map} options.app The app state
 * @param {Map} options.auth The auth state
 * @param {Map} options.page The page state
 * @param {Map} options.pmList The page manager list state
 * @param {Map} options.pmPlacement The page manager placement state
 * @param {Map} options.pmSection The page manager section state
 * @param {Map} options.pmScreen The page manager screen
 * @param {Map} options.videos The videos state
 * @param {String[]|List|Set} options.sectionIds The page manager section ids
 * @param {Number} [options.limit] A limit for the number of page manager sections
 */
export async function getPmSectionMultiple (options = {}) {
  try {
    const {
      dispatch,
      language,
      screenType,
      app = Map(),
      auth = Map(),
      page = Map(),
      resolver = Map(),
      pmList = Map(),
      pmPlacement = Map(),
      pmSection = Map(),
      pmScreen = Map(),
      videos = Map(),
      limit,
    } = options
    let { sectionIds = [] } = options
    if (List.isList(sectionIds) || Set.isSet(sectionIds)) {
      sectionIds = sectionIds.toArray()
    }
    if (limit) {
      sectionIds = _slice(sectionIds, 0, limit)
    }
    await eachPromise(sectionIds, (sectionId) => getPmSectionSingle({
      dispatch,
      language,
      screenType,
      app,
      auth,
      page,
      resolver,
      pmList,
      pmPlacement,
      pmSection,
      pmScreen,
      videos,
      sectionId,
    }))
  } catch (e) {
    // Do nothing
  }
}

/**
 * Get one page manger section and dispatch associated actions
 * @param {Object} options The options
 * @param {import('redux').Dispatch} options.dispatch Redux dispatch function
 * @param {String} options.language The request language
 * @param {String} options.screenType The page manager screen type
 * @param {Map} options.app The app state
 * @param {Map} options.auth The auth state
 * @param {Map} options.page The page state
 * @param {Map} options.pmList The page manager list state
 * @param {Map} options.pmPlacement The page manager placement state
 * @param {Map} options.pmSection The page manager section state
 * @param {Map} options.pmScreen The page manager screen
 * @param {Map} options.videos The videos state
 * @param {String} options.sectionId The page manager section ids
 */
export async function getPmSectionSingle (options = {}) {
  const {
    dispatch,
    language,
    auth = Map(),
    resolver = Map(),
    pmList = Map(),
    pmSection = Map(),
    sectionId,
    now = new Date(),
  } = options
  try {
    const processing = getPmSectionProcessing({ pmSection, sectionId })
    const errorCreated = getPmSectionErrorCreated({ pmSection, language, sectionId })
    const dataCreated = getPmSectionDataCreated({ pmSection, language, sectionId })
    const preventDataLoad = preventSectionDataLoad({ now, dataCreated })
    const isHome = resolver.get('path', '') === URL_HOME
    const isYogaTeachersPage = resolver.get('path', '') === URL_YOGA_TEACHERS
    const isSharePage = resolver.getIn(['data', 'type']) === RESOLVER_TYPE_SHARE

    // Remove this once AI related testing is done
    const usePGV = resolver.getIn(['query', 'usePGV']) === 'true' || false

    if (processing) {
      return
    }
    // Don't get page manager screen if there was an error recently
    // eslint-disable-next-line max-len
    if (errorCreated && now.getTime() < (errorCreated.getTime() + GET_PM_SECTION_ERROR_CACHE_TIME)) {
      return
    }
    // // Don't get page manager data if data was fetch recently
    if (preventDataLoad) {
      return
    }
    dispatch(setPmSectionProcessingAction(sectionId, true))
    const response = await apiGet(
      `page-manager/section/${sectionId}`,
      {
        language,
        LASpotlightOption: true,
        usePGV, // Remove this once AI related testing is done
      },
      { auth },
      TYPE_BROOKLYN,
    )
    const data = fromJS(_get(response, 'body', {}))
    const parentTermId = data.getIn(['data', 'footer', 'label']) ? _parseInt(data.getIn(['data', 'footer', 'label']), 10) : undefined
    const pageUrl = resolver.get('path')
    const preventNodeFetch = (tilesV2GraphqlActive && isHome && data.get('type') !== TYPE_PROMOTION && data.get('type') !== TYPE_PLACEMENT)
      || (isYogaTeachersV3Enabled && isYogaTeachersPage)
      || (isSharePageV3Enabled && isSharePage)

    // If there is a term id in the footer, fetch the parent term data if this the topics page
    // This allows the data to be fetched for the parent category so it can link to a page
    // like the child terms. This should be refactored at some point, as getting a string id
    // from the footer is a bit hacky
    if (parentTermId && _isNumber(parentTermId) && _startsWith(pageUrl, URL_TOPICS)) {
      dispatch(getTerms({
        ids: [parentTermId], language, fetchAsNodes: true, contentType: 'tag',
      }))
    }

    dispatch(setPmSectionAction({
      sectionId,
      data,
      processing: false,
      // Done will be set to true after all dependacies have loaded
      done: false,
    }))
    switch (data.get('type')) {
      case TYPE_CONTENT_LIST:
      case TYPE_PEOPLE_LIST:
      case TYPE_PORTALS:
      case TYPE_PROMOTION: {
        const content = (data.getIn(['data', 'content']) || List())
        if (List.isList(content) && !content.isEmpty()) {
          // Set makes the values unique
          const listIds = content.toSet().map((v) => v.get('listId')).toArray()
          dispatch(setMultiplePmListsProcessingAction(
            listIds,
            true,
          ))

          await getPmListMultiple({
            dispatch,
            language,
            sectionId,
            auth,
            listIds,
            pmList,
            preventNodeFetch,
          })
        }
        break
      }
      case TYPE_PLACEMENT:
      case TYPE_CONTENT_REFERENCES_LIST:
      default: {
        const tabs = (data.getIn(['data', 'tabs']) || List())
        const contentList = tabs.flatMap((v) => v.get('tabContent'))
        // Set makes the values unique
        const videosList = contentList.filter((v) => v.get('contentType') === 'video').toSet()
        if (!videosList.isEmpty()) {
          const ids = videosList.map((v) => v.get('contentId')).toArray()
          dispatch(getVideos({ ids, language, preventNodeFetch }))
        }
        // Set makes the values unique
        const seriesList = contentList.filter((v) => v.get('contentType') === 'series').toSet()
        if (!seriesList.isEmpty()) {
          const ids = seriesList.map((v) => Number(v.get('contentId'))).toArray()
          dispatch(getSeries({ ids, language, preventNodeFetch }))
        }

        // Set makes the values unique
        const promotions = contentList.filter((v) => v.get('contentType') === TYPE_PROMOTION).toSet()
        if (!promotions.isEmpty()) {
          const ids = promotions.map((v) => v.get('contentId')).toArray()
          dispatch(getPromotions({ ids, language, preventNodeFetch }))
        }

        break
      }
    }
    // Set done to true now that all dependacies are loaded
    dispatch(setPmSectionDoneAction(sectionId, true))
  } catch (e) {
    dispatch(setPmSectionErrorAction(sectionId, e))
  }
}
