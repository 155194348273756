export const SCHEMA = {
  type: null,
  _dataError: null,
  options: [],
}

export const SCHEMA_OPTION = {
  name: null,
  value: null,
  path: null,
}
