import _get from 'lodash/get'
import { get as getConfig } from 'config'
import { SET_APP_BOOTSTRAP_PHASE } from 'services/app/actions'
import { SET_RESOLVER_DATA } from 'services/resolver/actions'
import { getAuthIsLoggedIn } from 'services/auth'
import { getUserPortalMetaData } from 'services/portal'
import {
  URL_ACCOUNT_SETTINGS,
} from 'services/url/constants'
import { ROLE_ID_AMBASSADOR } from 'services/auth-access'
import { getAmbassador } from 'services/ambassadors'
import { AMBASSADORS_SET_DETAILS, AMBASSADORS_SET_DETAILS_PROCESSING } from 'services/ambassadors/actions'
import * as actions from './actions'

const config = getConfig()
const manageEmailCommunication = !!_get(config, ['features', 'userAccount', 'manageEmailCommunication'])

function isAccountSettings ({ state }) {
  const { resolver, auth } = state
  const path = resolver.getIn(['data', 'path'])
  const isLoggedIn = getAuthIsLoggedIn(auth)

  return path === URL_ACCOUNT_SETTINGS && isLoggedIn && manageEmailCommunication
}

export function watchUserPortalMetadata ({ after }) {
  return after(
    [actions.SET_USER_DATA, SET_APP_BOOTSTRAP_PHASE],
    async ({
      dispatch, state, prevState, action,
    }) => {
      const { auth, app } = state
      const { app: prevApp } = prevState
      const currentBootstrapComplete = app.get('bootstrapComplete')
      const prevBootstrapComplete = prevApp.get('bootstrapComplete')

      if (
        (getAuthIsLoggedIn(auth)
        && action.type === SET_APP_BOOTSTRAP_PHASE
        && currentBootstrapComplete
        && prevBootstrapComplete !== currentBootstrapComplete)
        || (action.type === actions.SET_USER_DATA)
      ) {
        dispatch({
          type: actions.SET_USER_PORTAL_METADATA_PROCESSING,
          payload: true,
        })

        const data = await getUserPortalMetaData({ auth })
        dispatch({
          type: actions.SET_USER_PORTAL_METADATA,
          payload: { data, processing: false },
        })
      }
    },
  )
}

export function watchUserAmbassadorRole ({ after }) {
  return after(
    [SET_APP_BOOTSTRAP_PHASE],
    async ({
      dispatch, state, prevState,
    }) => {
      const { auth } = state
      const { app } = state
      const { app: prevApp } = prevState
      const currentBootstrapComplete = app.get('bootstrapComplete')
      const prevBootstrapComplete = prevApp.get('bootstrapComplete')

      if (
        currentBootstrapComplete
        && prevBootstrapComplete !== currentBootstrapComplete
        && getAuthIsLoggedIn(auth)
        && auth.get('roleIds').includes(ROLE_ID_AMBASSADOR)
      ) {
        dispatch({
          type: AMBASSADORS_SET_DETAILS_PROCESSING,
          payload: { processing: true },
        })

        const data = await getAmbassador({ auth })
        const error = _get(data, 'errors')

        dispatch({
          type: AMBASSADORS_SET_DETAILS,
          payload: {
            data: error ? {} : data,
            error,
          },
        })
      }
    },
  )
}

export function watchAccountSettingsBootstrap ({ after }) {
  return after(
    [SET_APP_BOOTSTRAP_PHASE],
    async ({
      dispatch, state, prevState,
    }) => {
      const { app } = state
      const { app: prevApp } = prevState
      const currentBootstrapComplete = app.get('bootstrapComplete')
      const prevBootstrapComplete = prevApp.get('bootstrapComplete')

      if (currentBootstrapComplete && prevBootstrapComplete !== currentBootstrapComplete) {
        dispatch(actions.getUserContactData())
      }
    },
  )
    .when(isAccountSettings)
}

export function watchAccountSettingsSetResolverData ({ after }) {
  return after(
    [SET_RESOLVER_DATA],
    async ({
      dispatch,
    }) => {
      dispatch(actions.getUserContactData())
    },
  )
    .when(isAccountSettings)
}
