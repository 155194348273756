import _parseInt from 'lodash/parseInt'
import _assign from 'lodash/assign'
import _cloneDeep from 'lodash/cloneDeep'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _includes from 'lodash/includes'
import _filter from 'lodash/filter'
import _compact from 'lodash/compact'
import _uniq from 'lodash/uniq'
import _isEmpty from 'lodash/isEmpty'
import _toString from 'lodash/toString'
import _split from 'lodash/split'
import { fromJS, Map, List } from 'immutable'
import { Promise as BluebirdPromise } from 'bluebird'
import {
  getLocalPreferences,
  setLocalPreferences,
} from 'services/local-preferences'
import {
  get as apiGet,
  post as apiPost,
  put as apiPut,
  del as apiDelete,
  TYPE_AUTH_JSON,
  TYPE_BROOKLYN_JSON,
} from 'api-client'
import {
  getPrimary,
  getAllowedLanguages as allowedLanguages,
} from 'services/languages'
import { formatTracking } from 'services/checkout'

export const USER_CREATION_SOURCE_ZUORA = 'zuora'
export const USER_STATUS_BLOCKED = 'blocked'

const USER_SCHEMA = {
  uid: -1,
  name: '',
  mail: '',
  uuid: '',
  gcsi_user_uuid: '',
  firstName: '',
  lastName: '',
  birthday: '',
  profile: {
    picture: {
      small_28x28: '',
      hdtv_190x266: '',
    },
    bio: '',
    location: '',
    isPrivate: true,
  },
  avatar: {
    small: null,
  },
  userEntitlements: {
    start: '',
    end: '',
  },
  freeTrialNoBillingInfo: {
    ratePlanId: '',
    sku: '',
    entitlementDays: '',
  },
  billing_account_id: '',
  creation_source: '',
  account_owner_uid: '',
  email_registered_externally: '',
  created_at: '',
  checkout_complete: false,
  status: '',
}

const NEW_USER_SCHEMA = {
  _dataError: null,
  resultCode: null,
  available: null,
  success: null,
  childProfile: null,
}

const USER_PROFILE_IMAGE_SCHEMA = {
  _dataError: null,
  statusCode: null,
  success: null,
}

const USER_UPDATE_SCHEMA = {
  _dataError: null,
  statusCode: null,
  success: null,
  uid: -1,
  name: '',
  username: '',
  mail: '',
  birthday: '',
  firstName: '',
  lastName: '',
  email: '',
  gcsi_user_uuid: '',
  profile: {
    picture: {
      small_28x28: '',
      hdtv_190x266: '',
    },
    bio: '',
    location: '',
    isPrivate: true,
  },
  avatar: {
    small: null,
  },
}

const USER_CREATE_SCHEMA = {
  _dataError: null,
  error: null,
  errorDetail: '',
  errorMeta: '',
  requestStatus: null,
  success: false,
  uid: '',
  uuid: '',
  username: '',
  email: '',
  messages: [],
  codes: [],
  auth: null,
}

const LOCAL_STORAGE_KEY = 'user'
export const USERNAME_REGEX = /^[A-Za-z0-9@_.'-]{1,}$/

export async function get (options) {
  const { auth } = options

  const handleResponse = function handleResponse (res, _dataError) {
    const data = _get(res, 'body', {})
    return _assign(_cloneDeep(USER_SCHEMA), {
      _dataError,
      uid: _parseInt(_get(data, 'uid', -1)),
      uuid: _get(data, 'uuid'),
      name: _get(data, 'username', ''),
      mail: _get(data, 'email', ''),
      firstName: _get(data, 'first_name', ''),
      lastName: _get(data, 'last_name', ''),
      birthday: _get(data, 'birthday', ''),
      gcsi_user_uuid: _get(data, 'gcsi_user_uuid', ''),
      profile: {
        picture: {
          small_28x28: _get(data, ['profile', 'picture', 'small_28x28'], ''),
          hdtv_190x266: _get(data, ['profile', 'picture', 'hdtv_190x266'], ''),
        },
        bio: _get(data, ['profile', 'bio'], ''),
        location: _get(data, ['profile', 'location'], ''),
        isPrivate: _parseInt(_get(data, ['profile', 'isPrivate'], -1), 2),
      },
      avatar: {
        small: _get(data, ['profile', 'picture', 'small_28x28'], ''),
      },
      userEntitlements: {
        start: _get(data, ['user_entitlements', 0, 'start']),
        end: _get(data, ['user_entitlements', 0, 'end']),
      },
      freeTrialNoBillingInfo: {
        ratePlanId: _get(data, ['user_free_plan', 'rate_plan_id']),
        sku: _get(data, ['user_free_plan', 'sku']),
        entitlementDays: _get(data, ['user_free_plan', 'entitlement_days']),
      },
      billing_account_id: _get(data, 'billing_account_id'),
      creation_source: _get(data, 'creation_source'),
      account_owner_uid: _get(data, 'account_owner_uid'),
      email_registered_externally: _get(data, 'email_registered_externally'),
      created_at: _get(data, 'created_at'),
      xff: _get(data, 'xff'),
      checkout_complete: _get(data, 'checkout_complete'),
      status: _get(data, 'status', ''),
    })
  }

  if (!auth) {
    return handleResponse({}, true)
  }
  try {
    const res = await apiGet('user', { view: 'detail' }, { auth }, TYPE_AUTH_JSON)

    return handleResponse(res)
  } catch (e) {
    return handleResponse({}, true)
  }
}

export async function getEmailAvailability (options = {}) {
  const { email } = options
  try {
    const res = await apiPost('v1/checkout/account-check', { email }, null, TYPE_BROOKLYN_JSON)
    return handleUserAvailabilityResponse(res)
  } catch (e) {
    return handleUserAvailabilityResponse({}, true)
  }
}

export async function getUsernameAvailability (options = {}) {
  const { username } = options
  try {
    const res = await apiPost('v1/checkout/account-check', { username }, null, TYPE_BROOKLYN_JSON)
    return handleUserAvailabilityResponse(res)
  } catch (e) {
    return handleUserAvailabilityResponse({}, true)
  }
}

export function handleUserAvailabilityResponse (res, _dataError) {
  const data = _get(res, 'body', {})
  return _assign(_cloneDeep(NEW_USER_SCHEMA), {
    _dataError: _dataError || null,
    success: _get(data, 'success', null),
    available: _get(data, 'available', null),
    resultCode: _get(data, 'resultCode', null),
    childProfile: _get(data, 'childProfile', null),
    userAcquisitions: _get(data, 'userAcquisitions', []),
    xff: _get(data, 'xff', null),
  })
}

export function handleUserAvailabilityError (res, _dataError) {
  const data = _get(res, 'body', {})
  return _assign(_cloneDeep(NEW_USER_SCHEMA), {
    _dataError,
    success: _get(data, 'success'),
    available: _get(data, 'available'),
    resultCode: _get(data, 'resultCode'),
    childProfile: _get(data, 'childProfile', null),
    userAcquisitions: _get(data, 'userAcquisitions', []),
    xff: _get(data, 'xff', null),
  })
}

export async function putUserProfileImages (options = {}) {
  const { profile, avatar, auth } = options
  try {
    const res = await apiPut('v1/user/image', { profile, avatar }, { auth }, TYPE_AUTH_JSON)
    return handlePutUserProfileImages(res)
  } catch (e) {
    return handlePutUserProfileImages({}, true)
  }
}

export function handlePutUserProfileImages (res, _dataError) {
  const statusCode = _get(res, 'statusCode')
  const success = statusCode >= 200 && statusCode <= 299
  return _assign(_cloneDeep(USER_PROFILE_IMAGE_SCHEMA), {
    _dataError: _dataError || null,
    statusCode,
    success,
  })
}

export async function removeUserProfileImages (options = {}) {
  const { auth } = options
  try {
    const res = await apiDelete('v1/user/image', null, { auth }, TYPE_AUTH_JSON)
    return handleRemoveUserProfileImages(res)
  } catch (e) {
    return handleRemoveUserProfileImages({}, true)
  }
}

export function handleRemoveUserProfileImages (res, _dataError) {
  const statusCode = _get(res, 'statusCode')
  const success = statusCode >= 200 && statusCode <= 299
  return _assign(_cloneDeep(USER_PROFILE_IMAGE_SCHEMA), {
    _dataError: _dataError || null,
    statusCode,
    success,
  })
}

export async function getUserContactDataEmarsys (auth, language) {
  try {
    const res = await apiGet('email-contact', { language }, { auth }, TYPE_BROOKLYN_JSON)
    return _get(res, 'body', [])
  } catch (e) {
    return []
  }
}

export async function getUserEmailPreferencesById (id, language) {
  try {
    const res = await apiGet(`email-contact/${id}`, { language }, null, TYPE_BROOKLYN_JSON)
    return handleUserEmailPreferencesById(res)
  } catch (err) {
    return handleUserEmailPreferencesById({}, true)
  }
}

export function handleUserEmailPreferencesById (res, _dataError) {
  /* eslint-disable no-unneeded-ternary */
  const data = _get(res, 'body', [])
  const success = _dataError ? false : true
  return {
    _dataError: _dataError || null,
    success,
    data,
  }
  /* eslint-enable no-unneeded-ternary */
}

export async function updateUserInfo (options = {}) {
  const {
    auth,
    email,
    username,
    bio,
    location,
    password,
    firstName,
    lastName,
    shippingAddress,
    billingAddress,
    birthday,
  } = options
  try {
    const res = await apiPut(
      'v1/user',
      {
        email,
        bio,
        username,
        birthday,
        location,
        password,
        first_name: firstName,
        last_name: lastName,
        shipping_address: shippingAddress,
        billing_address: billingAddress,
      },
      { auth },
      TYPE_AUTH_JSON,
    )
    return handleUpdateUser(res)
  } catch (e) {
    return handleUpdateUser({}, true)
  }
}

export function handleUpdateUser (res, _dataError) {
  const data = _get(res, 'body', {})
  const statusCode = _get(res, 'statusCode')
  const success = statusCode >= 200 && statusCode <= 299
  return _assign(_cloneDeep(USER_UPDATE_SCHEMA), {
    _dataError: _dataError || null,
    statusCode,
    success,
    uid: _parseInt(_get(data, 'uid', -1)),
    name: _get(data, 'username', ''),
    mail: _get(data, 'mail', ''),
    username: _get(data, 'username', ''),
    birthday: _get(data, 'birthday'),
    firstName: _get(data, 'first_name', ''),
    lastName: _get(data, 'last_name', ''),
    email: _get(data, 'email', ''),
    gcsi_user_uuid: _get(data, 'gcsi_user_uuid', ''),
    profile: {
      picture: {
        small_28x28: _get(data, ['profile', 'picture', 'small_28x28'], ''),
        hdtv_190x266: _get(data, ['profile', 'picture', 'hdtv_190x266'], ''),
      },
      bio: _get(data, ['profile', 'bio'], ''),
      location: _get(data, ['profile', 'location'], ''),
      isPrivate: _parseInt(_get(data, ['profile', 'isPrivate'], -1), 2),
    },
    avatar: {
      small: _get(data, ['profile', 'picture', 'small_28x28'], ''),
    },
  })
}

export function setUserLanguage (data) {
  return _assign(_cloneDeep(USER_SCHEMA), {
    language: data,
  })
}

export async function getUserLocalData (options) {
  const { uid } = options
  return new BluebirdPromise(((resolve) => {
    const user = getLocalPreferences(uid, LOCAL_STORAGE_KEY) || {}

    resolve(fromJS(user))
  }))
}

export async function setUserLocalData (options = {}, auth) {
  const { uid, data } = options
  if (!data) {
    throw new Error('User localstorage set requires a data option.')
  }
  try {
    let user = await getUserLocalData({ uid })
    // eslint-disable-next-line no-param-reassign
    user = user.merge(data)
    setLocalPreferences(uid, LOCAL_STORAGE_KEY, user.toJS(), auth)
    return user
  } catch (e) {
    return Map()
  }
}

/**
 * Given a user object from state get the user's language
 * @param {import('immutable').Map} user A user object from state
 * @returns {List<String[]>|undefined} An array of languages or undefined if it does not exist
 */
export function getUserLanguage (user) {
  if (Map.isMap(user)) {
    return user.getIn(['data', 'language'])
  }
  return undefined
}

export function getCleanUserLanguage (language) {
  // split the string on anything that is not alpha if not an array
  const languageArray = !_isArray(language) ? _compact(_split(_toString(language), /[^A-Za-z]/)) : language
  // filter languages to only allowed languages
  const filteredLanguage = _filter(languageArray, (item) => {
    return _includes(allowedLanguages(), item)
  })
  // remove any duplicates
  let uniqLanguage = _uniq(filteredLanguage)

  // set english as the default if empty
  if (_isEmpty(uniqLanguage)) {
    uniqLanguage = ['en']
  }

  return uniqLanguage
}

/**
 * Given a user object from state get the user's language primary
 * @param {import('immutable').Map} user A user object from state
 * @returns {String|undefined} An array of languages or undefined if it does not exist
 */
export function getUserLanguagePrimary (user) {
  const languages = getUserLanguage(user) || List()
  return List.isList(languages) ? languages.first() : undefined
}

export async function getUserLocalDataLanguage (auth) {
  const user = await getUserLocalData({ uid: auth.get('uid') })
  return getUserLanguage(user)
}

export async function getUserLocalDataLanguageAlertBarAccepted (auth) {
  const user = await getUserLocalData({ uid: auth.get('uid') })
  return user.getIn(['data', 'languageAlertBarAccepted'])
}

export async function setUserLocalDataLanguageAlertBarAccepted (auth, value) {
  const user = await setUserLocalData({ uid: auth.get('uid'), data: { data: { languageAlertBarAccepted: value } } }, auth)
  return user.getIn(['data', 'languageAlertBarAccepted'])
}

export async function getUserPublic (uuid) {
  try {
    const res = await apiGet(`/v1/user/lookup/${uuid}`, null, null, TYPE_BROOKLYN_JSON)
    const { body } = res
    return transform(body)
  } catch (err) {
    return err
  }
}

export function transform (user) {
  const image = _get(user, 'profilePicture')
    || _get(user, ['profile', 'picture', 'hdtv_190x266'], '')
  return {
    url: _get(user, 'url') ? `/portal/${_get(user, 'url')}` : null,
    subscribers: _get(user, 'subscriberCount', 0),
    tagline: _get(user, 'tagline', ''),
    title: _get(user, 'username', ''),
    tags: _get(user, 'tags', []),
    id: _get(user, 'uuid'),
    image,
  }
}

export function selectUserLanguages (state) {
  const { user } = state
  const languages = getUserLanguage(user)
  return languages || List()
}

export function selectUserLanguagePrimary (state) {
  const { user } = state
  const languages = getUserLanguage(user)
  const primaryLanguage = getPrimary(languages)
  return primaryLanguage || undefined
}

export function handleUserCreateResponse (res, _dataError) {
  const data = _get(res, ['body'], {})
  const uid = _get(data, 'uid', '')
  const uuid = _get(data, 'uuid', '')
  const success = !!uid
  const username = _get(data, 'username', '')
  const email = _get(data, 'email', '')
  const errors = _get(data, 'errors')
  const requestStatus = _get(res, 'statusCode')
  const codes = _get(data, 'codes', [])
  const messages = _get(data, 'messages', [])
  let error
  let errorDetail
  let errorMeta

  if (errors && _isArray(errors) && errors.length > 0) {
    error = _get(errors, 0)
    errorDetail = _get(error, 'detail')
    errorMeta = _get(error, 'meta', []).join(' | ')
  }

  return _assign(_cloneDeep(USER_CREATE_SCHEMA), {
    error: _dataError || error,
    errorDetail,
    requestStatus,
    errorMeta,
    codes,
    messages,
    success,
    uid,
    uuid,
    username,
    email,
    auth: data,
  })
}

/**
 * Brooklyn call to create a new user.
 * @param {Object} [options={}] The options
 * @param {String} options.email The email of the user
 * @param {String} options.password The password of the user
 * @param {String} options.firstName The first name of the user
 * @param {String} options.lastName The last name of the user
 * @param {Array} options.language The language of the user
 * @param {Boolean} options.emailOptIn Whether or not the user opted in to email communication
 */
export async function userCreate (options = {}) {
  try {
    const {
      email,
      password,
      firstName,
      lastName,
      language,
      emailOptIn,
      creationSource,
      inboundTracking,
    } = options
    const data = {
      email,
      password,
      firstName,
      lastName,
      creationSource: creationSource || USER_CREATION_SOURCE_ZUORA,
      language,
      emailOptIn,
      tracking: formatTracking(inboundTracking),
    }

    // create the user without logging in, as that
    // is handled in the web app flow
    const res = await apiPost('v1/user/create', data, null, TYPE_BROOKLYN_JSON)

    return handleUserCreateResponse(res)
  } catch (err) {
    return handleUserCreateResponse({}, true)
  }
}

export function getUserAvatarName (user) {
  // sometimes the data is not ready the first time the function is called
  // return a blank space to avoid errors
  if (!user) {
    return ' '
  }

  const name = user.get('first_name') || user.get('firstName') || user.get('displayName')
  const username = user.get('username') || user.get('name') || ' '

  return (!name || /^(gaia|friend of|friend)$/i.test(name)) ? username : name
}
