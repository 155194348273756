import { Map, fromJS } from 'immutable'
import _pipe from 'lodash/fp/pipe'
import { createModel as createTileModel } from 'services/tile'
import {
  SERIES_KEY,
  VIDEOS_KEY,
  FAQS_KEY,
  TERM_KEY,
  PROMOTIONS_KEY,
  PRODUCTS_KEY,
} from '../entities'
import { getSafeNodeId } from '../utils'
import { getConvertedNodes } from './getConvertedNodes'

const defaultErrorMsg = ({ id }) => `unknown type of error for node with id ${id}`

const safeGetData = (node) => {
  const hasError = node.get('error')
  const data = node.get('data')

  return Map.isMap(data) && !hasError ? data.toJS() : {}
}
const convertToTile = (getErrorMsg = defaultErrorMsg) => ({ id, node }) => {
  const data = safeGetData(node)

  const tile = createTileModel(data)

  const { nid } = tile

  if (nid < 1) {
    const errorMsg = getErrorMsg({ id, nid })
    return node.withMutations((mutableState) => {
      mutableState.set('data', null)
      mutableState.set('error', errorMsg)
    })
  }

  return node.withMutations((mutableState) => {
    mutableState.set('error', null)
    mutableState.set('data', fromJS(tile))
  })
}

const geVideosErrorMsg = ({ id, nid }) => `Bad video ${id}, response nid: ${nid}`
function convertToVideoTile ({ id, node }) {
  return convertToTile(geVideosErrorMsg)({ id, node })
}

const getSeriesErrorMsg = ({ id, nid }) => `Bad series ${id}, response nid: ${nid}`
function convertToSeriesTile ({ id, node }) {
  return convertToTile(getSeriesErrorMsg)({ id, node })
}

const getPromotionsErrorMsg = ({ id, nid }) => `Bad promotions ${id}, response nid: ${nid}`
function convertToPromotionsTile ({ id, node }) {
  return convertToTile(getPromotionsErrorMsg)({ id, node })
}

const getFaqs = ({ id, nid }) => `Bad faqs ${id}, response nid: ${nid}`
function convertToFaqsTile ({ id, node }) {
  return convertToTile(getFaqs)({ id, node })
}

const getTermsTile = ({ id, nid }) => `Bad term ${id}, response nid: ${nid}`
function convertToTermsTile ({ id, node }) {
  return convertToTile(getTermsTile)({ id, node })
}

const getProductErrorMsg = ({ id, nid }) => `Bad product ${id}, response nid: ${nid}`
function convertToProductTile ({ id, node }) {
  return convertToTile(getProductErrorMsg)({ id, node })
}

function convertToTerm ({ node }) {
  const hasError = node.get('error')

  if (hasError) {
    return node.withMutations((mutableState) => {
      mutableState.set('data', [])
    })
  }
  return node
}

const getSafeState = (state) => {
  if (!Map.isMap(state)) {
    throw new Error('state must be an Immutable Map')
  }
  return state
}

// the root store state isn't an immutable object,
// so we need to get a sub-state from the plain Object
const getNodeRootStore = (state) => state.node

const selectByNodePath = (state, subPath) => _pipe(
  getNodeRootStore,
  getSafeState,
  (safeState) => safeState.getIn(subPath) || Map(),
)(state)

export function selectVideos (state) {
  const path = [VIDEOS_KEY]
  return getConvertedNodes(convertToVideoTile)(selectByNodePath(state, path))
}

export function selectVideoById (state, {
  id, language, subPath = [], defaultValue = Map(),
}) {
  const videos = selectVideos(state)
  return videos.getIn([getSafeNodeId(id), language, ...subPath]) || defaultValue
}

export function selectSeries (state) {
  const path = [SERIES_KEY]
  return getConvertedNodes(convertToSeriesTile)(selectByNodePath(state, path))
}

export function selectSeriesById (state, { id, language, subPath = [] }) {
  const series = selectSeries(state)
  return series.getIn([getSafeNodeId(id), language, ...subPath]) || Map()
}

export function selectFaqs (state) {
  const path = [FAQS_KEY]
  return getConvertedNodes(convertToFaqsTile)(selectByNodePath(state, path))
}

export function selectTerms (state, formatAsTiles = true) {
  const path = [TERM_KEY]

  if (formatAsTiles) {
    return getConvertedNodes(convertToTermsTile)(selectByNodePath(state, path))
  }

  return getConvertedNodes(convertToTerm)(selectByNodePath(state, path))
}

export function selectTermById (state, { id, language, subPath = [] }) {
  const terms = selectTerms(state, false)
  return terms.getIn([getSafeNodeId(id), language, ...subPath]) || Map()
}

export function selectPromotions (state) {
  const path = [PROMOTIONS_KEY]
  return getConvertedNodes(convertToPromotionsTile)(selectByNodePath(state, path))
}

export function selectPromotionById (state, { id, language, subPath = [] }) {
  const promotions = selectPromotions(state)
  return promotions.getIn([getSafeNodeId(id), language, ...subPath]) || Map()
}

export function selectProducts (state) {
  const path = [PRODUCTS_KEY]
  return getConvertedNodes(convertToProductTile)(selectByNodePath(state, path))
}
